import React from 'react';
import './App.css';
import './styles/fonts.css';
import './styles/palette.css';

import Main from './elements/Main/Main';

function App() {
  // Update the early return for refactor mode
    return <Main />;
}

export default App;
